
import Config from "./Config";

export default {

    _mobileNav: null,

    navigate(link, options = {}){
        if(link.startsWith("http")){
            window.open(link);
        } else if(link.startsWith("app://")){
            const parts = link.substr(6).split("/");
            this._navigateApp(parts[0], parts[1], options.withOffset);
        }
    },

    _navigateApp(action, name, withOffset = false){
        const id = this._getInAppTargetId(name);
        const element = id ? document.querySelector(`#${id}`) : null;
        let top = element.offsetTop;

        if(name === "newsletter") top = element.getBoundingClientRect().top;
        console.log(`Action: ${action}, Params: ${name}, ID: ${id}`);

        if(element){
            if(this._mobileNav){
                this._mobileNav.close();
                setTimeout(() => {
                    this._scrollIdIntoView(top, -Config.navigation.mobile.getHeight());
                }, Config.navigation.mobile.openCloseTransitionTime);
            } else {
                setTimeout(() => {
                    const offset = withOffset ? -Config.navigation.desktop.stick.height : 0;
                    this._scrollIdIntoView(top, offset);
                }, 100);
            }
        }
    },

    _scrollIdIntoView(top, offset=0){
        const scrollContainer = document.querySelector("#root > div > div");

        scrollContainer.scroll({
           top: top + offset,
           behavior: "smooth"
        });
    },

    _getInAppTargetId(targetName){
        switch (targetName){
            case "home": return "page_home";
            case "newsletter": return "page_newsletter";
            case "videos": return "page_videos";
            case "tour": return "page_tour";
            case "pictures": return "page_images";
            case "about": return "page_about";
            case "contact": return "page_contact";
        }
        console.error(`Unkown target name "${targetName}"`);

        return null;
    },

    registerMobileNavigation(elm){
        this._mobileNav = elm;
    },

    navigateCurrentUrl(){
        const pageName = window.location.pathname.substr(1);

        if(pageName.replace("/", "").trim().length){
            this._navigateApp("page", pageName);
        }
    }

};