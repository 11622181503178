const breakpoints = {
    mobile: 650,
    tablet: 1280,
    desktop: 99999
};

const Config = {
    isMobile: () => Config.currentBreakpoint === Config.breakpoints.mobile,
    isTablet: () => Config.currentBreakpoint === Config.breakpoints.tablet,
    isDesktop: () => Config.currentBreakpoint === Config.breakpoints.desktop,

    currentBreakpoint: getCurrentBreakpoint(),
    breakpoints,
    navigation: {
        mobile: {
            getHeight: () => {
                if(Config.isMobile()) return 65;
                if(Config.isTablet()) return 80;
                return 0;
            },
            openCloseTransitionTime: 500,
            navItemDelay: {
                stdIn: 0.2,
                in: 0.04,
                out: 0.04,
                stdOut: 0
            }
        },
        desktop: {
            stick: {
                height: 40
            }
        }
    }
};

function getCurrentBreakpoint(){
    const width = window.innerWidth;

    if(width > breakpoints.tablet) return breakpoints.desktop;
    else if(width > breakpoints.mobile) return breakpoints.tablet;
    return breakpoints.mobile;
}

export default Config;