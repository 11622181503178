import React from "react";

const styles = {
    root: {
        position: "fixed",
        top: "3vh",
        left: "8vw",
        width: 30,
        height: 20,
        zIndex: 1000
    },
    child: {
        width: "100%",
        height: 2,
        backgroundColor: "white",
        borderRadius: "100vw",
        margin: "10px 0px",
        transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
    },
    child_active1: {
        transform: "rotateZ(45deg) translateY(11px)"
    },
    child_active2: {
        transform: " rotateZ(-45deg) translate(-2px, -8px)"
    }
};

export default class MenuButton extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            active: false
        };
    }

    render(){
        return (
            <div style={styles.root} onClick={this.onClick.bind(this)}>
                <div style={{...styles.child, ...(this.state.active ? styles.child_active1 : {})}} />
                <div style={{...styles.child, ...(this.state.active ? styles.child_active2 : {})}} />
            </div>
        );
    }

    onClick(){
        if(typeof this.props.onClick === "function") this.props.onClick();
    }

    open(){
        this.setState({active: true});
    }

    close(){
        this.setState({active: false});
    }

}