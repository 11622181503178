import React, {Component} from "react";
import StyleProvider from "../functions/StyleProvider";
import Config from "../functions/Config";

const styleData = {
    base: {
        arrow: {
            position: "absolute",
            bottom: 80,
            width: Math.sin(Math.PI/180 * 45) * 2 * 40,
            left: "50%",
            transform: "translate(-50%, 0px)",
            animation: "arrow_animation 1s ease-out infinite running"
        },
        part: {
            position: "absolute",
            top: 0,
            width: 40,
            borderBottom: "1px solid white"
        },
        left: {
            left: -Math.sin(Math.PI/180 * 45) * 40 / 2,
            transform: "rotateZ(45deg)"
        },
        right: {
            left: Math.sin(Math.PI/180 * 45) * 40 / 2,
            transform: "rotateZ(-45deg)"
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            arrow: {

            }
        }
    }, {
        minWidth: Config.breakpoints.tablet,
        maxWidth: Config.breakpoints.desktop,
        style: {
            arrow: {

            }
        }
    }]
};

const styleProvider = new StyleProvider(styleData);

export default class ArrowDown extends Component {

    constructor(props){
        super(props);
    }

    render(){
        const style = styleProvider.get();

        return (
            <div style={style.arrow}>
                <div style={{...style.part, ...style.left}} />
                <div style={{...style.part, ...style.right}} />
            </div>
        );
    }

}