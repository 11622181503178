import Config from "../../functions/Config";


export default {
    base: {
        gigsContainer: {
            position: "relative"
        },
        gigEntryContainer: {
            paddingBottom: 40
        },
        gigContainer: {
            display: "flex",
            flexDirection: "column"
        },
        gigDate: {
            fontWeight: "bold"
        },
        gigSpans: {
            display: "block",
            paddingRight: 10
        },
        gigLocation: {
            textTransform: "none"
        },
        gigVenue: {
            color: "#EBD999"
        },
        button: {
            position: "static",
            marginTop: 5,
            marginBottom: 5,
            transform: "none"
        },
        noGigs: {
            width: "100%",
            display: "inline-block",
            textAlign: "center",
            marginTop: 50
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            gigsContainer: {
                width: "80vw",
                minHeight: "80vh",
                marginLeft: "10vw"
            },
            gigEntryContainer: {
                marginTop: 10,
            },
            gigContainer: {
                padding: "10px 0",
                marginTop: 10
            },
            separator: {
                borderBottom: "1px solid rgba(130, 130, 130, 0.13)"
            },
            gigDate: {
                fontSize: 13,
                letterSpacing: 0.5
            },
            gigLocation: {
                fontSize: 16,
                letterSpacing: 0.4
            },
        }
    }, {
        minWidth: Config.breakpoints.mobile,
        maxWidth: Config.breakpoints.tablet,
        style: {
            gigsContainer: {
                marginLeft: "10vw",
                width: "80vw",
                minHeight: "80vh"
            },
            gigEntryContainer: {
                marginTop: 10
            },
            gigContainer: {
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20
            },
            gigSpans: {
                flex: 1
            },
            gigLocation: {
                flex: 2
            },
        }
    }, {
        minWidth: Config.breakpoints.tablet,
        maxWidth: Config.breakpoints.desktop,
        style: {
            gigsContainer: {
                marginLeft: "10vw",
                width: "80vw",
                minHeight: "80vh"
            },
            gigEntryContainer: {
                marginTop: 10,
                paddingBottom: 10
            },
            gigContainer: {
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20
            },
            gigSpans: {
                flex: 1
            },
            gigLocation: {
                flex: 2
            },
        }
    }]
};