import Config from "../../functions/Config";


export default {
    base: {
        text: {
            display: "block",
            textAlign: "center"
        },
        title: {
            fontFamily: "Abel",
            fontSize: 24
        },
        subtitle: {
            fontSize: 14
        },
        failed: {
            width: "100%",
            display: "inline-block",
            textAlign: "center",
            marginTop: 50
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            text: {
                marginTop: 10
            },
            iframe: {
                width: "80vw",
                height: "calc(80vw / 16 * 9)",
                margin: "2vh 10vw",
                marginTop: 10,
                paddingBottom: 20
            }
        }
    }, {
        minWidth: Config.breakpoints.mobile,
        maxWidth: Config.breakpoints.desktop,
        style: {
            text: {
                marginTop: 10
            },
            iframe: {
                width: "60vw",
                height: "calc(60vw / 16 * 9)",
                margin: "2vh 20vw",
                marginTop: 10,
                paddingBottom: 20
            }
        }
    }]
};