import Config from "../../functions/Config";


export default {
    base: {
        container: {
            position: "relative",
            boxSizing: "border-box"
        },
        text: {
            textAlign: "center",
            display: "block"
        },
        email: {
            display: "block",
            textAlign: "center",
            width: "100%",
            color: "white",
            paddingBottom: "10vh"
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            container: {
                width: "90vw",
                top: "30%",
                left: "5vw",
                padding: "10px",
            },
            email: {
                marginTop: 10
            }
        }
    }, {
        minWidth: Config.breakpoints.tablet,
        maxWidth: Config.breakpoints.desktop,
        style: {
            text: {
                marginTop: "10vh",
                marginBottom: 20
            },
            email: {

            }
        }
    }]
};