import React from "react";

import Page from "../../components/Page"
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import ToggleButton from "../../components/tour/ToggleButton";
import Config from "../../functions/Config";
import StyleProvider from "../../functions/StyleProvider";

import styleData from "./styles";

const styleProvider = new StyleProvider(styleData);

export default class Home extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            upcomingPastToggle: true,
            pastGigsCount: 5,
            upcomingGigsCount: Infinity,
            gigs: {
                upcoming: [],
                past: []
            }
        };
    }

    componentDidMount() {
        const fallback = () => {
            console.error("Failed to fetch gigs.");
        };

        fetch(window.location.origin + window.location.pathname + "api.php?action=songkick").then(e => {
            e.json().then(gigs => {
                this.setState({gigs});
            }).catch(fallback);
        }).catch(fallback);
    }

    render(){
        const style = styleProvider.get();

        return (
            <Page id={"page_tour"}>
                <div style={style.gigsContainer}>
                    <Heading value={"Tour"} />
                    { this.renderGigs(style) }
                </div>
            </Page>
        );
        // <ToggleButton onToggle={(b) => this.setState({upcomingPastToggle: b})} />
    }

    onClick(){
        if(this.state.upcomingPastToggle){
            this.setState({upcomingGigsCount: this.state.upcomingGigsCount + 5});
        } else {
            this.setState({pastGigsCount: this.state.pastGigsCount + 5});
        }
    }

    renderGigs(style){
        const gigArray = this.state.upcomingPastToggle ? this.state.gigs.upcoming : this.state.gigs.past;
        const gigAmount = this.state.upcomingPastToggle ? this.state.upcomingGigsCount : this.state.pastGigsCount;

        return (
            <div style={style.gigEntryContainer}>
                { gigArray.slice(0, gigAmount).map((v, i) => this.renderGig(v, i, gigArray.length, style)) }
                { gigArray.length === 0 ? this.renderNoGig(style) : null }
                { this.renderShowMoreButton() }
            </div>
        );
    }

    renderNoGig(style){
        const label = this.state.upcomingPastToggle ? "Coming soon" : "Failed to load past gigs";
        return <span style={style.noGigs}>{label}</span>
    }

    renderShowMoreButton(){
        if(this.state.upcomingPastToggle && this.state.upcomingGigsCount >= this.state.gigs.upcoming.length) return null;
        if(!this.state.upcomingPastToggle && this.state.pastGigsCount >= this.state.gigs.past.length) return null;

        return <Button value={"Show more"} onClick={this.onClick.bind(this)} />;
    }

    renderGig(gig, i, n, style){
        const containerStyle = i < n-1 ? {...style.gigContainer, ...style.separator} : style.gigContainer;
        const venue = gig.venue ? gig.venue : "/ /";
        let dateString = "/ /";

        if(gig.timestamp){
            const date = new Date(gig.timestamp);
            const month = date.toLocaleString("en-US", {"month": "short"});
            const day = date.toLocaleString("en-US", {"weekday": "short"});
            const year = date.getUTCFullYear().toString().substr(2);
            dateString = `${month} ${date.getDate()} ${day} ${year}`;
        }

        return (
            <div key={i} style={containerStyle}>
                <span style={{...style.gigSpans, ...style.gigDate}}>{dateString}</span>
                <span style={{...style.gigSpans, ...style.gigLocation, ...style.gigVenue}}>{venue}</span>
                <span style={{...style.gigSpans, ...style.gigLocation}}>{gig.city}</span>
                { Config.isMobile() || !this.state.upcomingPastToggle ? null :
                    <div style={{flex: 1}}>
                        <Button style={style.button} value={"Tickets"} href={gig.uri} />
                    </div> }
            </div>
        );
    }

}