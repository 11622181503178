import Config from "../../functions/Config";


export default {
    base: {
        heading: {

        },
        vinyl: {
            width: "15vw"
        },
        infoText: {
            display: "block"
        },
        button: {
            marginTop: "48px"
        },
        outerContainer: {
            position: "relative",
            display: "flex",
            flexFlow: "row nowrap",
            width: "60vw",
            left: "20vw",
            padding: "70px 0"
        },
        innerContainer: {
            marginTop: "32px",
            marginLeft: "20px"
        },
        image: {
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            infoText: {

            },
            vinyl: {
                width: "100%",
                marginTop: "30vh"
            },
            outerContainer: {
                flexFlow: "column",
                width: "80vw",
                height: "100vh",
                left: "10vw"
            },
            innerContainer: {
                marginLeft: "0px"
            }
        }
    }, {
        minWidth: Config.breakpoints.mobile,
        maxWidth: Config.breakpoints.tablet,
        style: {
            infoText: {
                textAlign: "center",
                marginTop: "16px"
            },
            vinyl: {
                width: "40vw",
                position: "relative",
                left: "50%",
                transform: "translate(-50%, 0)",
                marginTop: "10vh"
            },
            outerContainer: {
                flexFlow: "column",
                width: "80vw",
                height: "100vh",
                left: "10vw"
            },
            innerContainer: {
                marginLeft: "0px"
            }
        }
    }, {
        minWidth: Config.breakpoints.tablet,
        maxWidth: Config.breakpoints.desktop,
        style: {
            page: {
                backgroundColor: "rgba(0, 0, 0, 0.4)"
            },
            imageContainer: {
                width: "60vw",
                marginLeft: "20vw"
            },
            image: {
                display: "inline-block",
                width: "calc((60vw - 3 * 2 * 25px) / 3)",
                height: "calc((60vw - 3 * 2 * 25px) / 3)",
                margin: 25,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
            },
            heading: {
                paddingBottom: 20
            }
        }
    }]
};
