import React from "react";

import logo from "../assets/logo.png";
import Config from "../functions/Config";
import StyleProvider from "../functions/StyleProvider";

const styleData = {
    base: {
        logo: {
            position: "fixed",
            zIndex: 1001
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            logo: {
                width: "50vw",
                left: "32vw",
                top: "calc(5vh - 0.13550420168067226 * 50vw / 2)"
            }
        }
    }, {
        minWidth: Config.breakpoints.mobile,
        maxWidth: Config.breakpoints.tablet,
        style: {
            logo: {
                width: 300,
                top: "calc(3vh)",
                left: "calc((100vw - 300px) / 2)"
            }
        }
    }, {
        minWidth: Config.breakpoints.tablet,
        maxWidth: Config.breakpoints.desktop,
        style: {
            logo: {
                position: "relative",
                width: 300,
                flex: 1,
                marginTop: -3,
                paddingLeft: 40,
                paddingRight: 40
            }
        }
    }]
};

const styleProvider = new StyleProvider(styleData);

export default class Logo extends React.Component {

    constructor(props){
        super(props);
    }

    render(){
        let style = styleProvider.get();

        if(Config.isMobile()){
            // style.logo.top = 0.13550420168067226 * window.innerWidth * 0.5;
        }

        return (
            <img style={style.logo} src={logo} alt={"logo"} />
        );
    }

}
