import React from "react";
import Config from "../functions/Config";
import StyleProvider from "../functions/StyleProvider";

const styleData = {
    base: {
        width: "100%",
        position: "relative"
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            minHeight: "100vh"
        }
    }]
};

const styleProvider = new StyleProvider(styleData);

export default class Page extends React.Component {

    constructor(props){
        super(props);

        this.state = {

        };
    }

    render(){
        const style = styleProvider.get();

        return (
            <section {...this.props} style={{...style, ...this.props.style}}>
                {this.props.children}
            </section>
        );
    }

}