

import Config from "../../functions/Config";

const containerBorderRadius = 3;

export default {
    base: {
        container: {
            display: "flex",
            flexFlow: "row nowrap",
            cursor: "pointer"
        },
        text: {
            flex: 1,
            textTransform: "none",
            textAlign: "center",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            color: "rgba(255, 255, 255, 0.8)",
            padding: "8px 0"
        },
        leftText: {
            borderTopLeftRadius: containerBorderRadius,
            borderBottomLeftRadius: containerBorderRadius
        },
        rightText: {
            borderTopRightRadius: containerBorderRadius,
            borderBottomRightRadius: containerBorderRadius
        },
        inactive: {
            backgroundColor: "rgba(100, 100, 100, 0.3)",
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)"
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            container: {
                paddingBottom: 10
            },
        }
    }, {
        minWidth: Config.breakpoints.tablet,
        maxWidth: Config.breakpoints.desktop,
        style: {
            container: {
                position: "absolute",
                right: 20,
                top: 20
            },
            text: {
                padding: "10px 15px"
            }
        }
    }]
};