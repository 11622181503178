import React from "react";
import Page from "../../components/Page";
import Heading from "../../components/Heading";

import styleData from "./styles";
import StyleProvider from "../../functions/StyleProvider";

const styleProvider = new StyleProvider(styleData);

export default class Contact extends React.Component {

    constructor(props){
        super(props);
    }

    render(){
        const style = styleProvider.get();

        return (
            <Page id={"page_contact"}>
                <Heading value={"Contact"} />
                <section style={style.container}>
                    <span style={style.text}>for booking and all other inquiries email</span>
                    <a href={"mailto:hello@stillsane.org"} style={style.email}>hello@stillsane.org</a>
                </section>
            </Page>
        );
    }

}