import React, {Component} from "react";

const list = {
    instagram: {
        url: "https://instagram.com/stillsanestillsane",
        icon: require("../../assets/instagram.svg")
    },
    email: {
        url: "",
        icon: require("../../assets/email.svg")
    },
    spotify: {
        url: "https://open.spotify.com/artist/6qUErrwMeC3h50gxVYxrJC",
        icon: require("../../assets/spotify.svg")
    },
    twitter: {
        url: "https://twitter.com/michelstirner",
        icon: require("../../assets/twitter.svg")
    },
    youtube: {
        url: "https://youtube.com/stillsanestillsane",
        icon: require("../../assets/youtube.svg")
    }
};

const styles = {
    icon: {
        borderRadius: "100vw",
        filter: "invert(1)",
        transition: "all 0.5s ease"
    },
    iconHover: {
        filter: "invert(0)"
    },
    container: {
        display: "flex",
        justifyContent: "center"
    }
};

export default class SocialIcon extends Component {

    constructor(props){
        super(props);

        this.state = {
            hover: false
        };

        if(typeof this.props.name !== "string" || Object.keys(list).indexOf(this.props.name.toLowerCase()) === -1){
            console.error(`Unknown name ${this.props.name}. Please provide a valid social media name [${Object.keys(list).join(", ") }]`);
        } else {
            const name = this.props.name.toLowerCase();
            this.data = {name: name[0].toUpperCase() + name.substr(1), ...list[name]};
        }
    }

    render(){
        const outerStyle = typeof this.props.style === "object" ? {...this.props.style, ...styles.container} : styles.container;
        const innerStyle = this.state.hover ? {...styles.icon, ...styles.iconHover} : styles.icon;

        return (
            <a href={this.data.url} target={"_blank"} style={outerStyle} onClick={this.props.onClick}>
                <img style={innerStyle} src={this.data.icon} alt={this.data.name}
                    onMouseEnter={this.toggleHover.bind(this)} onMouseLeave={this.toggleHover.bind(this)} />
            </a>
        );
    }

    toggleHover(){
        this.setState({hover: !this.state.hover});
    }

}