import React from "react";
import Page from "../../components/Page";
import Button from "../../components/Button";
import Heading from "../../components/Heading";

import styleData from "./styles";
import StyleProvider from "../../functions/StyleProvider";
import Config from "../../functions/Config";

import vinylImage from "../../assets/ep_cover.jpg";

const styleProvider = new StyleProvider(styleData);

export default class Shop extends React.Component {

    constructor(props){
        super(props);

        this.state = {

        };
    }

    componentDidMount() {

    }

    render(){
        const style = styleProvider.get();

        return (
            <Page id={"page_shop"} style={style.page}>
                <div style={style.outerContainer}>
                    <img style={style.vinyl} src={vinylImage} />
                    <div style={style.innerContainer}>
                        <span style={style.infoText}>
                            THE VERY LIMITED VINYL OF OUR DEBUT EP "MAKING PLANS OR THE THINGS THAT HAPPEN IN BETWEEN" IS AVAILABLE NOW.
                            <br />
                            <br />
                            VISIT OUR BANDCAMP STORE TO BUY THE VINYL AND CHECK OUT OUR SELFMADE MERCH ITEMS.
                        </span>
                        <Button 
                            style={style.button}
                            href={"https://stillsanestillsane.bandcamp.com/"} 
                            value={"STORE"} 
                        />
                    </div>
                </div>
            </Page>
        );
    }

}
