import React from "react";

import Page from "../../components/Page";
import styleData from "./styles";
import StyleProvider from "../../functions/StyleProvider";
import {Newsletter2Go, NewsletterStatusMessage} from "../../functions/Newsletter2Go";
import Heading from "../../components/Heading";

const styleProvider = new StyleProvider(styleData);

export default class Newsletter extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            name: "",
            email: "",
            accepted: false,
            status: null
        };
        this.formRef = null;
        this.nameRef = null;
        this.emailRef = null;
        this.termsRef = null;
    }

    render(){
        const style = styleProvider.get();
        const newsletterFormClassNames = this.state.status ? "newsletter_form-flipped" : "";
        const newsletterFormStatusClassNames = this.state.status ? "newsletter_form_status-flipped" : "";

        return (
            <Page id={"page_newsletter"}>
                <section style={style.container} id={"newsletter_form"} className={newsletterFormClassNames}>
                    <form style={style.form} ref={(ref) => this.formRef = ref}>
                        <Heading value={"Join our Newsletter"} />
                        <br />
                        <input
                            style={style.inputText}
                            type={"text"}
                            placeholder={"NAME"}
                            required={true}
                            ref={(ref) => this.nameRef = ref}
                            value={this.state.name}
                            onChange={ (e) => {
                                this.setState({...this.state, name: e.target.value});
                            }}
                        />
                        <br />
                        <input
                            style={style.inputText}
                            type={"email"}
                            placeholder={"EMAIL"}
                            required={true}
                            ref={(ref) => this.emailRef = ref}
                            value={this.state.email}
                            onChange={ (e) => {
                                this.setState({...this.state, email: e.target.value});
                            }}
                        />
                        <br />
                    <span style={style.termsWrapper}>
                        <input
                            style={style.checkbox}
                            id={"newsletter_checkbox"}
                            type={"checkbox"}
                            required={true}
                            ref={(ref) => this.termsRef = ref}
                            value={this.state.accepted}
                            onChange={ (e) => {
                                this.setState({...this.state, accepted: e.target.value});
                            }}
                        />
                        <span style={style.terms}>
                            I accept the <a href={"https://www.newsletter2go.de/datenschutz/"} target={"_blank"}>data protection regulations</a>
                        </span>
                    </span>
                        <br />
                        <br />
                        <input
                            className={"signUp"}
                            style={style.signUp}
                            type={"button"}
                            value={"SIGN UP"}
                            onClick={ (e) => {
                                if(this.formRef.reportValidity()){
                                    (async () => {
                                        const status = await Newsletter2Go.subscribe(this.state.name, this.state.email);
                                        this.setState({
                                            ...this.state,
                                            status
                                        });
                                    })();
                                }
                            }}
                        />
                    </form>
                </section>
                <section style={style.container} id={"newsletter_form_status"} className={newsletterFormStatusClassNames}>
                    <span style={{width: "60vw"}}>
                        { 
                            NewsletterStatusMessage[this.state.status] 
                        }
                        <br />
                        <br />
                        <a 
                            href={""} 
                            style={{fontSize: 13}} 
                            onClick={ (e) => {
                                e.preventDefault(); 
                                this.setState({
                                    ...this.state,
                                    status: null 
                                })
                            }}>
                            Back to registration
                        </a>
                    </span>
                </section>
            </Page>
        );
    }

}
