import Config from "../../functions/Config";


export default {
    base: {
        container: {
            position: "relative",
            boxSizing: "border-box",
            padding: "10px"
        },
        text: {
            display: "block",
            color: "#eee",
            textAlign: "center",
            textShadow: "0px 2px 4px #555",
            fontWeight: 300,
            fontSize: 16,
            lineHeight: "30px",
            whiteSpace: "pre-line"
        },
        single_title: {
            //fontSize: 45,
            fontSize: 40,
            marginBottom: 30
        },
        iconContainer: {
            display: "flex",
            marginTop: 15
        },
        icon: {
            flex: 1
        },
        button: {
            textTransform: "upper-case",
            fontSize: 15,
            letterSpacing: 3,
            padding: "10px 20px"
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            container: {
                position: "absolute",
                top: "50%",
                transform: "translate(0, -50%)",
                width: "90vw",
                left: "5vw"
            },
            single_title: {
                fontSize: 28,
                marginBottom: 10,
                whiteSpace: "normal",
                paddingBottom: "8px"
            },
            text: {
                lineHeight: ""
            },
        }
    }, {
        minWidth: Config.breakpoints.mobile,
        maxWidth: Config.breakpoints.tablet,
        style: {
            container: {
                height: "100vh",
                paddingTop: "40vh"
            },
            button: {
                marginTop: 30
            }
        }
    }, {
        minWidth: Config.breakpoints.tablet,
        maxWidth: Config.breakpoints.desktop,
        style: {
            container: {
                height: "100vh",
                paddingTop: "40vh"
            },
            button: {
                marginTop: 30
            }
        }
    }]
};
