import React from "react";
import navItems from "../../data/navigation"
import Navigator from "../../functions/Navigator";
import StyleProvider from "../../functions/StyleProvider";
import Config from "../../functions/Config";

const styleData = {
    base: {
        container: {
            position: "sticky",
            top: 0,
            zIndex: 999,
            backgroundColor: "#000"
        },
        navItemContainer: {
            width: "60vw",
            marginLeft: "20vw",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center"
        },
        navItem: {
            flex: 1,
            lineHeight: Config.navigation.desktop.stick.height + "px",
            fontFamily: "Source Code Pro",
            cursor: "pointer"
        }
    },
    breakpoints: []
};

const styleProvider = new StyleProvider(styleData);

export default class DesktopStickyNavigation extends React.Component {

    render(){
        const style = styleProvider.get();

        return (
            <div style={style.container}>
                <div style={style.navItemContainer}>
                    {
                        navItems.map((v, i) => {
                            return (
                                <span key={i} style={style.navItem}
                                      onClick={() => Navigator.navigate(v.link, {withOffset: true})}>{v.title}</span>
                            )
                        })
                    }
                </div>
            </div>
        );
    }

}