import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import Navigator from "./functions/Navigator";

ReactDOM.render(<App />, document.getElementById('root'));

Navigator.navigateCurrentUrl();

const script = document.createElement("script");
script.src = "https://cookieconsent.popupsmart.com/src/js/popper.js";
script.onload = () => {
    window.start.init({
        Palette: "palette1",
        Mode: "banner bottom",
        Theme: "wire",
        Time: "1",
        Location: " https://bit.ly/3atCXrV",
        ButtonText: "GOT IT",
        Message: "this website uses cookies to ensure you get the best experience on our website"
    });
}
document.head.appendChild(script);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
