import Config from "../../functions/Config";


export default {
    base: {
        heading: {

        },
        image: {
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            image: {
                width: "70vw",
                height: "70vw",
                margin: "4vh 15vw 0vh 15vw"
            }
        }
    }, {
        minWidth: Config.breakpoints.mobile,
        maxWidth: Config.breakpoints.tablet,
        style: {
            imageContainer: {
                width: "80vw",
                marginLeft: "10vw"
            },
            image: {
                display: "inline-block",
                width: "calc((80vw - 3 * 2 * 20px) / 3)",
                height: "calc((80vw - 3 * 2 * 20px) / 3)",
                margin: 20
            },
            heading: {
                paddingBottom: 20
            }
        }
    }, {
        minWidth: Config.breakpoints.tablet,
        maxWidth: Config.breakpoints.desktop,
        style: {
            imageContainer: {
                width: "60vw",
                marginLeft: "20vw"
            },
            image: {
                display: "inline-block",
                width: "calc((60vw - 3 * 2 * 25px) / 3)",
                height: "calc((60vw - 3 * 2 * 25px) / 3)",
                margin: 25,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
            },
            heading: {
                paddingBottom: 20
            }
        }
    }]
};