import Config from "../functions/Config";


export default {
    base: {
        heading: {
            display: "block",
            textAlign: "center",
            fontFamily: "Abel",
            fontSize: 36,
            letterSpacing: 2,
            marginBottom: 10
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {

        }
    }, {
        minWidth: Config.breakpoints.tablet,
        maxWidth: Config.breakpoints.desktop,
        style: {
            heading: {
                paddingTop: 22
            }
        }
    }]
};