import React, {Component} from "react";
import StyleProvider from "../../functions/StyleProvider";

import css from "../../functions/css";
import styleData from "./ToggleButton.style";

const styleProvider = new StyleProvider(styleData);

export default class ToggleButton extends Component {

    constructor(props){
        super(props);

        this.state = {
            toggled: true
        };
    }

    render(){
        const style = styleProvider.get();
        const leftStyle = css(style.text, style.leftText, !this.state.toggled ? style.inactive : {});
        const rightStyle = css(style.text, style.rightText, this.state.toggled ? style.inactive : {});

        return (
            <div style={style.container}>
                <span onClick={() => this.toggle(true)} style={leftStyle}>upcoming</span>
                <span onClick={() => this.toggle(false)} style={rightStyle}>past</span>
            </div>
        );
    }

    toggle(toggled){
        if(this.state.toggled !== toggled){
            this.setState({toggled});
            if(typeof this.props.onToggle === "function") this.props.onToggle(toggled);
        }
    }

}