import React, {Component} from "react";
import Config from "../functions/Config";

import background1 from "../assets/background1.jpg";
import background2 from "../assets/background2.jpg";
import background3 from "../assets/background3.jpg";
import background4 from "../assets/background4.jpg";

export default class BackgroundProvider extends Component {

    constructor(props){
        super(props);

        if(!this.props.elm){
            console.error("BackgroundProvider needs an elm attribute.");
        }
    }

    render(){
        if(!Config.isDesktop()) return this.props.elm;

        return this.createBackgroundContainer();
    }

    createBackgroundContainer(){
        const bg = this.props.bg;
        let style = null;

        if(bg === "home") style = this.setBackgroundImage(background1);
        else if(bg === "videos") style = this.setBackgroundColor("#000");
        else if(bg === "tour") style = this.setStaticBackgroundImage(background2, {backgroundPositionY: "18%"});
        else if(bg === "images") style = this.setBackgroundImage(background3);
        else if(bg === "about") style = this.setStaticBackgroundImage(background2, {backgroundPositionY: "18%"});
        else if(bg === "contact") style = this.setBackgroundImage(background4);

        return <div style={style} id={this.props.id || ""}>{this.props.elm}</div>;
    }

    setBackgroundImage(img, additionalStyles = {}){
        return {
            backgroundImage: `url(${img})`,
            backgroundSize: "cover",
            ...additionalStyles
        };
    }

    setStaticBackgroundImage(img, additionalStyles){
        const style = this.setBackgroundImage(img, additionalStyles);

        style.backgroundAttachment = "fixed";

        return style;
    }

    setBackgroundColor(color){
        return {
            backgroundColor: color
        };
    }

}
