import React, {Component} from "react";
import Config from "../functions/Config";
import StyleProvider from "../functions/StyleProvider";

import styleData from "./Heading.style";

const styleProvider = new StyleProvider(styleData);

export default class Heading extends Component {

    constructor(props){
        super(props);
    }

    render(){
        const value = this.props.value ? this.props.value : "Value";
        let style = styleProvider.get().heading;

        if(this.props.style) style = {...style, ...this.props.style};

        return (
            <span style={style}>{value}</span>
        );
    }

}