import React from 'react';

import ScrollContainer from "../components/ScrollContainer.js"

import Home from "../pages/home/index"
import Tour from "../pages/tour/index"

import Navigation from "../components/navigation/Navigation";
import backgroundImage from "../assets/background1.jpg";
import Images from "../pages/images";
import Shop from "../pages/shop";
import Videos from "../pages/videos";
import About from "../pages/about";
import Contact from "../pages/contact";
import Config from "../functions/Config";
import StyleProvider from "../functions/StyleProvider";
import BackgroundProvider from "../components/BackgroundProvider";
import Newsletter from "../pages/newsletter";

const styleData = {
    base: {
        backgroundContainer: {
            position: "absolute",
            width: "100vw",
            height: "100vh",
            overflow: "hidden"
        },
        backgroundImage: {
            position: "absolute",
            filter: "brightness(0.7)",
            height: "100vh",
            minWidth: "100vw",
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            backgroundImage: {
                left: "50%",
                transform: "translateX(-50%)"
            }
        }
    }]
};
const styleProvider = new StyleProvider(styleData);

class App extends React.Component {

    constructor(props){
        super(props);
    }

    render(){
        return (
            <ScrollContainer>
                { this.renderMobileBackground() }
                <Navigation />
                <BackgroundProvider elm={<Home />} bg={"home"} />
                <BackgroundProvider elm={<Newsletter />} bg={"newsletter"} id={"page_newsletter_container"} />
                <BackgroundProvider elm={<Shop />} bg={"images"} />
                <BackgroundProvider elm={<Tour />} bg={"tour"} />
                <BackgroundProvider elm={<Videos />} bg={"videos"} />
                <BackgroundProvider elm={<Images />} bg={"images"} />
                <BackgroundProvider elm={<About />} bg={"about"} />
                <BackgroundProvider elm={<Contact />} bg={"contact"} />
                {/* <span style={{ */}
                {/*     width: "100vw", */}
                {/*     display: "inline-block", */}
                {/*     textAlign: "center", */}
                {/*     padding: "16px", */}
                {/*     position: "relative", */}
                {/*     background: "black", */}
                {/*     color: "white", */}
                {/*     boxSizing: "border-box" */}
                {/* }}> */}
                {/*     The project was funded by a grant from the Baden-Württemberg Ministry of Science, Research and the Arts. */}
                {/* </span> */}
            </ScrollContainer>
        );
    }

    renderMobileBackground(){
        if(!Config.isDesktop()){
            const style = styleProvider.get();

            return (
                <div style={style.backgroundContainer}>
                    <img style={style.backgroundImage} src={backgroundImage} alt={"background"} />
                </div>
            );
        }

        return null;
    }

    checkBreakpoint(){
        let breakpoint = Config.currentBreakpoint;
        const width = window.innerWidth;

        if(width > Config.breakpoints.tablet) breakpoint = Config.breakpoints.desktop;
        else if(width > Config.breakpoints.mobile) breakpoint = Config.breakpoints.tablet;
        else breakpoint = Config.breakpoints.mobile;

        if(breakpoint !== Config.currentBreakpoint){
            Config.currentBreakpoint = breakpoint;
            this.setState({});
        }
    }

    componentWillMount() {
        this.checkBreakpoint();
    }

    componentDidMount() {
        window.addEventListener("resize", this.checkBreakpoint.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkBreakpoint.bind(this));
    }

}

export default App;
