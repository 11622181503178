import Config from "../../functions/Config";

export default {
    base: {
        container: {
        },
        heading: {
               
        },
        form: {
            position: "relative",
            boxSizing: "border-box",
            width: "35vw",
            left: "50%",
            transform: "translate(-50%, 0)",
            padding: "32px 0"
        },
        inputText: {
            display: "block",
            width: "100%",
            padding: "16px",
            textAlign: "center",
            fontSize: "16px",
            boxSizing: "border-box",
            background: "transparent",
            border: "2px solid white",
            color: "white"
        },
        signUp: {
            padding: "16px 0",
            position: "relative",
            width: "40%",
            left: "50%",
            transform: "translate(-50%, 0)",
            background: "transparent",
            border: "2px solid white",
            fontSize: "16px",
            color: "white"
        },
        termsWrapper: {
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            left: "50%",
            transform: "translate(-50%, 0)"
        },
        checkbox: {
            width: "20px",
            height: "20px"
        },
        terms: {
            color: "grey",
            marginLeft: "8px",
            verticalAlign: "super",
            fontSize: "14px"
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            container: {
                height: "100vh"
            },
            form: {
                top: "50%",
                width: "80vw",
                transform: "translate(-50%, -50%)"
            }
        }
    }, {
        minWidth: Config.breakpoints.mobile,
        maxWidth: Config.breakpoints.tablet,
        style: {
            container: {
                height: "100vh"
            },
            form: {
                top: "50%",
                width: "60vw",
                transform: "translate(-50%, -50%)"
            }
        }
    }, {
        minWidth: Config.breakpoints.tablet,
        maxWidth: Config.breakpoints.desktop,
        style: {
            container: {
            },
        }
    }]
};
