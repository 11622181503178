import React, {Fragment} from "react";

import MobileNavigation from "./MobileNavigation";
import Config from "../../functions/Config";
import DesktopStickyNavigation from "./DektopStickyNavigation";
import DesktopNavigation from "./DesktopNavigation";

export default class Navigation extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            showStickyMenu: false
        };

        this.navigation = null;

        window.scrollHook = (scrollY) => {
            const shouldShow = !Config.isMobile() && !Config.isTablet() && scrollY > window.innerHeight * 0.75;

            if(shouldShow !== this.state.showStickyMenu){
                this.setState({showStickyMenu: shouldShow});
            }
        };
    }

    render(){
        if(Config.isMobile() || Config.isTablet()) return <MobileNavigation ref={r => this.navigation = r} />;
        return this.state.showStickyMenu ? <DesktopStickyNavigation/> : null;
    }

    onClick(){
        if(this.navigation) this.navigation.onClick();
    }

}