import React, {Component} from "react";

import navItems from "../../data/navigation";
import Navigator from "../../functions/Navigator";
import StyleProvider from "../../functions/StyleProvider";
import Logo from "../Logo";

const styleData = {
    base: {
        navContainer: {
            position: "absolute",
            top: 30,
            left: "10vw",
            width: "80vw",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
            alignItems: "center"
        },
        navItem: {
            display: "table-cell",
            textAlign: "center",
            flex: 1,
            cursor: "pointer"
        }
    }  ,
    breakpoints: []
};

const styleProvider = new StyleProvider(styleData);

export default class DesktopNavigation extends Component {

    constructor(props){
        super(props);
    }

    render(){
        const style = styleProvider.get();

        return (
            <div style={style.navContainer}>
                { this.renderNavItem(0, 3, style) }
                <Logo />
                { this.renderNavItem(3, 6, style) }
            </div>
        );
    }

    renderNavItem(from, to, style){
        return navItems.slice(from, to).map((v, i) => {
            return <span key={i} style={style.navItem}
                  onClick={() => Navigator.navigate(v.link)}>{v.title}</span>
        });
    }

}