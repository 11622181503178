import React from "react";

const styles = {
    wrapper: {
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    container: {
        width: "calc(100% + 16px)",
        height: "100%",
        paddingRight: "16px",
        overflowX: "hidden",
        overflowY: "scroll"
    }
};

export default class ScrollContainer extends React.Component {

    constructor(props){
        super(props);

        this.state = {

        };
    }

    render(){
        return (
            <div style={styles.wrapper} {...this.props}>
                <div style={styles.container} onScroll={this.callScrollHook}>
                    {this.props.children}
                </div>
            </div>
        );
    }

    callScrollHook(e){
        if(typeof window.scrollHook === "function") window.scrollHook(e.target.scrollTop);
    }

}