import React from "react";

import backgroundImage from "../../assets/background1.jpg";
import navItems from "../../data/navigation"

import MenuButton from "./MenuButton";
import Logo from "../Logo";

import Navigator from "../../functions/Navigator";
import StyleProvider from "../../functions/StyleProvider";
import Config from "../../functions/Config";

const styleData = {
    base: {
        container: {
            height: Config.navigation.mobile.getHeight(),
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            overflow: "hidden",
            zIndex: 999,
            transition: `height ${Config.navigation.mobile.openCloseTransitionTime}ms linear`
        },
        container_active: {
            height: "100vh",
            display: "block"
        },
        backgroundImage: {
            filter: "brightness(0.7)",
            height: "100vh"
        },
        navItemContainer: {
            position: "absolute",
            top: "50vh",
            left: "10vw",
            width: "80vw",
            transform: "translate(0, -50%)"
        },
        navItems: {
            display: "block",
            marginTop: 20,
            fontSize: 24,
            textAlign: "center",
            color: "#eee",
            opacity: 0
        },
        navItems_active: {
            opacity: 1
        },
        cleaner: {
            top: Config.navigation.mobile.getHeight(),
            opacity: 0,
            position: "absolute",
            width: "100vw",
            height: 2,
            left: 0,
            marginTop: -8,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            transition: `top ${Config.navigation.mobile.openCloseTransitionTime}ms linear, opacity 500ms linear`
        },
        cleaner_active: {
            top: "100vh",
            opacity: 1,
            marginTop: -2,
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            backgroundImage: {
                left: "50%",
                transform: "translateX(-50%)"
            }
        }
    }]
};
const styleProvider = new StyleProvider(styleData);

export default class MobileNavigation extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            active: false,
            transitionEnded: true
        };

        this.menuButton = null;
        this.timeoutId = null;
    }

    componentDidMount() {
        Navigator.registerMobileNavigation(this);
    }

    componentWillUnmount() {
        Navigator.registerMobileNavigation(null);
    }

    open(){
        this.menuButton.open();
        this.setState({active: true, transitionEnded: false});
        if(this.timeoutId !== null) clearTimeout(this.timeoutId);
    }

    close(){
        this.menuButton.close();
        this.setState({active: false, transitionEnded: false});

        this.timeoutId = setTimeout(() => {
            this.setState({transitionEnded: true});
            this.timeoutId = null;
        }, Config.navigation.mobile.openCloseTransitionTime);
    }

    render(){
        const style = styleProvider.get();

        return (
            <section>
                <MenuButton onClick={() => this.onClick()} ref={(ref) => this.menuButton = ref} />
                <Logo />
                <div style={this.getContainerStyles()}>
                    <div>
                        <img style={style.backgroundImage} src={backgroundImage} alt={"background"} />
                    </div>
                    <nav style={style.navItemContainer}>
                        {
                            navItems.map((v, i) => {
                                return (
                                    <span key={i} style={this.getNavItemStyles(i)}
                                          onClick={() => Navigator.navigate(v.link)}>{v.title}</span>
                                )
                            })
                        }
                    </nav>
                    <div style={this.getNavCleanerStyles()} />
                </div>
            </section>
        );
    }

    getContainerStyles(){
        const styles = styleProvider.get();
        let style = styles.container;

        if(this.state.active) style = {...style, ...styles.container_active};
        if(!this.state.transitionEnded)  style = {...style, display: "block"};

        return style;
    }

    getNavItemStyles(i){
        const styles = styleProvider.get();
        const time = this.getNavItemTransitionTime(i);
        const style = {
            ...styles.navItems,
            transition: `opacity 0.2s ease-in-out ${time}s`
        };
        if(this.state.active) return {...style, ...styles.navItems_active};
        return style;
    }

    getNavItemTransitionTime(i){
        const nid = Config.navigation.mobile.navItemDelay;

        if(this.state.active){
            return nid.stdIn + nid.in * i;
        } else {
            return nid.stdOut + nid.out * navItems.length - nid.out * i;
        }
    }

    getNavCleanerStyles(){
        const styles = styleProvider.get();
        if(this.state.active) return {...styles.cleaner, ...styles.cleaner_active};
        return styles.cleaner;
    }

    onClick(){
        if(this.state.active) this.close();
        else this.open();
    }

}
