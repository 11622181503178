
export default class StyleProvider {

    constructor(style){
        this.baseStyle = style.base;
        this.breakpoints = style.breakpoints;
    }

    get(){
        let finalStyle = this.baseStyle || {};

        for(let breakpoint of this.breakpoints){
            if(this.match(breakpoint)) finalStyle = this.merge(finalStyle, breakpoint.style);
        }

        return finalStyle;
    }

    match(style){
        return window.innerWidth >= style.minWidth && window.innerWidth < style.maxWidth;
    }

    merge(base, style){
        const keys = Object.keys(style);

        for(let key of keys){
            if(!base[key]){
                base[key] = style[key];
            } else {
                base[key] = {...base[key], ...style[key]};
            }
        }

        return base;
    }

}