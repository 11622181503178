import Config from "../../functions/Config";


export default {
    base: {
        container: {
            position: "relative",
            boxSizing: "border-box",
            width: "90vw",
            left: "5vw"
        },
        text: {
            display: "block",
            textAlign: "justify",
            padding: "20px",
            textTransform: "none"
        }
    },
    breakpoints: [{
        minWidth: 0,
        maxWidth: Config.breakpoints.mobile,
        style: {
            container: {
            }
        }
    }, {
        minWidth: Config.breakpoints.tablet,
        maxWidth: Config.breakpoints.desktop,
        style: {
            text: {
                paddingBottom: 30
            }
        }
    }]
};