import React from "react";
import Page from "../../components/Page";
import Button from "../../components/Button";
import Heading from "../../components/Heading";

import styleData from "./styles";
import StyleProvider from "../../functions/StyleProvider";
import Config from "../../functions/Config";

import image1 from "../../assets/image/1.jpg";
import image2 from "../../assets/image/2.jpg";
import image3 from "../../assets/image/3.jpg";
import image4 from "../../assets/image/4.jpg";
import image5 from "../../assets/image/5.jpg";
import image6 from "../../assets/image/6.jpg";
import image7 from "../../assets/image/7.jpg";
import image8 from "../../assets/image/8.jpg";
import image9 from "../../assets/image/9.jpg";

const styleProvider = new StyleProvider(styleData);

export default class Images extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            images: [
                image1, image2, image3, image4, image5, image6, image7, image8, image9
            ]
        };
    }

    componentDidMount() {
        /*
        const fallback = () => {
            console.error("Failed to fetch pictures. Falling back to defaults...");
            this.setState({images: []});
        };

        fetch(window.location.origin + window.location.pathname + "api.php?action=instagram").then(e => {
            e.json().then(images => {
                this.setState({images});
            }).catch(fallback);
        }).catch(fallback);
         */
    }

    getImageQuality(){
        if(Config.isMobile()){
            if("connection" in navigator && navigator.connection.type){
                return navigator.connection.type !== "cellular" ? "highRes" : "lowRes";
            }

            return "lowRes";
        }

        return "highRes";
    }

    render(){
        const style = styleProvider.get();
        const imageCount = Config.isMobile() ? 4 : this.state.images.length;

        return (
            <Page id={"page_images"}>
                <Heading value={"Pictures"} style={style.heading} />
                <div style={style.imageContainer}>
                    {
                        this.state.images.slice(0, imageCount).map((v, i) =>
                            <div key={i} style={{...style.image, backgroundImage: `url(${v})`}}>
                            </div>
                        )
                    }
                </div>
                <Button href={"https://instagram.com/stillsanestillsane"} value={"Show more"} />
            </Page>
        );
    }

}
