const BASE_URL = "https://api.newsletter2go.com"
const SUBSCRIBE_ENDPOINT = "/forms/submit/4quhz1dz-5biop6r7-wng?type=subscribe"

const NewsletterStatus = {
    ALREADY_SUBSCRIBED: "ALREADY_SUBSCRIBED",
    ERROR: "ERROR",
    SUBSCRIBED: "SUBSCRIBED",
    UNAVAILABLE: "UNAVAILABLE"
};

const NewsletterStatusMessage = {
    [NewsletterStatus.ALREADY_SUBSCRIBED]: "you already subscribed to the newsletter with this email address. thanks for sticking with us!",
    [NewsletterStatus.ERROR]: "uuh, something went wrong here. please fill your info again and hit sign up!",
    [NewsletterStatus.SUBSCRIBED]: "thanks so much for subscribing! to verify it’s you, please hit the link in the email we just sent you. after that you won’t miss any important updates! :)",
    [NewsletterStatus.UNAVAILABLE]: "uuh, it seems like the newsletter service is currently unavailable. please check back later!"
};


const Newsletter2Go = {
    
    async subscribe(name, email) {
        try {
            const response = await fetch(BASE_URL + SUBSCRIBE_ENDPOINT, {
                method: "POST",
                body: JSON.stringify({
                    recipient: {
                        first_name: name,
                        email
                    },
                    lists: [],
                    captcha: {}
                })
            });
            const json = await response.json();
            console.log(json);
            const error = json.value[0].result.error;
            if(error.failed > 0){
                if(error.recipients.duplicate.length) return NewsletterStatus.ALREADY_SUBSCRIBED;
                else return NewsletterStatus.ERROR;
            } else {
                return NewsletterStatus.SUBSCRIBED;
            }
        } catch(e){
            return NewsletterStatus.UNAVAILABLE;
        }
    }

};

export {Newsletter2Go, NewsletterStatus, NewsletterStatusMessage};