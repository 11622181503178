import React from "react";
import Page from "../../components/Page";
import Heading from "../../components/Heading";
import StyleProvider from "../../functions/StyleProvider";

import styleData from "./styles";

const styleProvider = new StyleProvider(styleData);

export default class Videos extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            videos: []
        }
    }

    componentDidMount() {
        const fallback = () => {
            console.error("Failed to fetch videos.");
        };

        fetch(window.location.origin + window.location.pathname + "api.php?action=youtube").then(e => {
            e.json().then(videos => {
                this.setState({videos});
            }).catch(fallback);
        }).catch(fallback);
    }

    render(){
        const style = styleProvider.get();

        return (
            <Page id={"page_videos"} style={{minHeight: "200px"}}>
                <Heading value={"Videos"} />
                {
                    this.state.videos.map((v, i) => {
                        return <div key={i}>
                            <span style={{...style.text, ...style.title}}>{v.title}</span>
                            <span style={{...style.text, ...style.subtitle}}>{v.subtitle}</span>
                            <iframe style={style.iframe} key={i} src={"https://www.youtube.com/embed/" + v.id}
                                    frameBorder="0" allow="autoplay; encrypted-media; picture-in-picture"
                                    scrolling="no" allowFullScreen />
                        </div>
                    })
                }
                {
                    this.state.videos.length !== 0 ? null :
                        <span style={style.failed}>Failed to load videos</span>
                }
            </Page>
        );
    }

}