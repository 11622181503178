import React, {Component} from "react";

const styles = {
    mobile: {
        display: "inline-block",
        position: "relative",
        left: "50%",
        transform: "translate(-50%, 0)",
        marginTop: 20,
        marginBottom: 20,
        border: "solid 2px #fff",
        color: "#fff",
        fontSize: 16,
        padding: "6px 12px",
        textDecoration: "none",
        textTransform: "none",
        cursor: "pointer"
    }
};

export default class Button extends Component {

    constructor(props){
        super(props);
    }

    render(){
        const value = this.props.value ? this.props.value : "Value";
        let style = styles.mobile;

        if(this.props.style) style = {...style, ...this.props.style};

        return (
            <span onClick={this.onClick.bind(this)} style={style}>{value}</span>
        );
    }

    onClick(){
        if(this.props.href) window.open(this.props.href, "_blank");
        else if(typeof this.props.onClick === "function") this.props.onClick();
    }

}